import HttpService from './httpService';
import config from '../config';
class OrderService {
    private httpService: HttpService;
    
    constructor() {
      this.httpService = new HttpService();
    }

    public createOrder = (body: any) => {
      return this.httpService.post(`${config.baseURL}orders`, JSON.stringify(body));
    }
  
  }
  
  export default OrderService;