import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux';
import {
    orderDeleteProduct,
    changeOrderProductCount,
    incrementOrderProductCount,
    decrementOrderProductCount,
    handleOrderSuccesModal,
    setSuccessCreateOrder,
    handleOrderData,
} from '../features/order/orderActions';
import { selectors as orderSelectors } from '../features/order';
import ClientService from '../services/clientsService';
import OrderService from '../services/orderService';
import { handleDigitInputOnKeyDown } from '../helpers/checkDigit';
import config from '../config';
import { I18n, Translate } from 'react-redux-i18n';

const clientService = new ClientService();
const orderService = new OrderService();

const DeliveryOrder: React.FC = (props: any) => {
    const {order, language} = props;
    const totalSum = useSelector(orderSelectors.getTotalPrice);
    const currentDeliveryType = useSelector(orderSelectors.getDeliveryTypeByCode);
    const deliveryDate = useSelector(orderSelectors.getFormatDeliveryDate);
    const dispatch = useDispatch();

    useEffect(() => {
    }, [language]);

    const [description, setDescription] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [address, setAddress] = useState('');
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);

    const [client, setClient] = useState(null);

    const {data = []} = order;

    const deleteOrderProduct = (product: any) => {
        dispatch(orderDeleteProduct(product));
    }

    const incDecCount = (product: any, isInc: boolean) => {
        if (isInc) {
            dispatch(incrementOrderProductCount(product));
        } else {
            if (product.count - 1 > 0) {
                dispatch(decrementOrderProductCount(product));
            }
        }
    }

    const isCanSubmit = () => {
        if (order.data && order.data.length > 0 && cellPhone && !isDisabledSubmit) {
            if (currentDeliveryType && currentDeliveryType.code !== 'self' && !address) {
                return false;
            }
            return true;
        }
        return false;
    }

    const changeCount = (product: any, event: any) => {
        const value = (event.target.value > 0 ? event.target.value : 1);
        dispatch(changeOrderProductCount(product, Number(value)));
    }

    const changeDescription = (event: any) => {
        setDescription(event.target.value);
    }

    const changeCellPhone = (event: any) => {
        setCellPhone(event.target.value);
    }

    const blurCellPhone = (event: any) => {
        setIsDisabledSubmit(true);
        getClient();
    }

    const changeAddress = (event: any) => {
        setAddress(event.target.value)
    }

    const getClient = () => {
        clientService.getClientByCellPhone(cellPhone)
            .then((res: any) => {
                setClient(res);
                setIsDisabledSubmit(false);
            })
            .catch(e => {
                setClient(null);
                setIsDisabledSubmit(false);
            })
    }

    const getPurchasesDto = () => {
        const data = order.data;
        if (data.length > 0) {
            return data.map((item: any) => {
                return {
                    product: {id: item.id},
                    count: item.count
                }
            });
        }
        return [];
    }

    const createOrder = (client: any) => {
        const purchases: any = getPurchasesDto();
        const body = {
            client: {id: client.id},
            orderAddress: address || null,
            purchases,
            sellPoint: { id: Number(config.defaultSellPoint) },
            deliveryType: currentDeliveryType,
            payments: { paymentType: { id: Number(config.defaultPaymentType) }},
            comment: description || null,
            minExecuteDate: order.deliveryDate,
            maxExecuteDate: order.deliveryDate,
        };
        orderService.createOrder(body)
            .then(res => {
                dispatch(setSuccessCreateOrder(res.id));
                setDescription('');
                setCellPhone('');
                setAddress('');
                dispatch(handleOrderSuccesModal(true));
            })
    }

    const submit = () => {
        if (isCanSubmit()) {
            if (client) {
                createOrder(client);
            } else {
                const body = {
                    phone: cellPhone,
                    firstName: `client_${Date.now()}`,
                };
                clientService.createClient(body)
                    .then(res => {
                        createOrder(res);
                    });
            }
        }
    }

    return (
        <aside className={`delivery-order` + (order.isShowOrderData ? ' open' : '')}>
            <span className="mobile-close" onClick={() => dispatch(handleOrderData(false))}>
                <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            <form className="delivery-order_form">
                <h3><Translate value={'order.value'} /></h3>
                <ul className="delivery-order_info">
                    <li><Translate value={`form.children.deliveryType.value`} />: <span className="font-bold">{
                        currentDeliveryType ?
                          <Translate value={`form.children.deliveryType.children.${currentDeliveryType.code}.value`} /> :
                          ''
                    }</span></li>
                    <li><Translate value={`form.children.deliveryDate.children.dateDelivery.value`} />: <span className="font-bold">{deliveryDate || ''}</span></li>
                </ul>

                <div className="delivery-order_scroll scrollbar-outer">
                    {
                        data && data.length > 0 &&
                        data.map((item: any) => (
                            <div className="delivery-order_one" key={item.id}>
                                <div className="delivery-order_quantity">
                                    <button type="button" onClick={() => incDecCount(item, false)}>-</button>
                                    <input type="text" className="delivery-order_count" value={item.count} onChange={(e) => changeCount(item, e)} />
                                    <button type="button" className="btn-blue" onClick={() => incDecCount(item, true)}>+</button>
                                </div>
                                <p className="delivery-order_name">{item.title}</p>
                                <span className="delivery-order_price">
                                    {item.price ? Number(item.price * item.count).toFixed(2) : Number(0).toFixed(2)} <Translate value={`currency.children.uah.value`} />
                                </span>
                                <span className="delivery-order_cancel" onClick={() => deleteOrderProduct(item)}>X</span>
                            </div>
                        ))
                    }
                </div>

                <div className="delivery-order_main">
                    <div className="form-row">
                        <textarea placeholder={I18n.t(`orderForm.children.comment.value`)}
                                  value={description}
                                  onChange={changeDescription}></textarea>
                    </div>
                    <div className="form-row">
                        <input type="text"
                               placeholder={I18n.t(`orderForm.children.phone.value`)}
                               value={cellPhone}
                               onChange={changeCellPhone}
                               onBlur={blurCellPhone}
                               onKeyDown={handleDigitInputOnKeyDown} />
                    </div>
                    {
                        currentDeliveryType && currentDeliveryType.code !== 'self' &&
                        <div className="form-row">
                            <input type="text"
                                   placeholder={I18n.t(`orderForm.children.address.value`)}
                                   value={address}
                                   onChange={changeAddress} />
                        </div>
                    }
                    <div className="delivery-order_total">
                        <span><Translate value={`orderForm.children.total.value`} /></span>
                        <span>{totalSum.toFixed(2)} <Translate value={`currency.children.uah.value`} /></span>
                    </div>
                    <a onClick={submit} className={'btn' + (isCanSubmit() ? ' btn-red' : ' btn-red_disable')}>
                        <Translate value={`orderForm.children.submit.value`} />
                    </a>
                </div>
            </form>
        </aside>
    );
}

const mapStateToProps = (state: any) => {
    return {
        language: state.language,
        order: state.order,
    }
};

export default connect(mapStateToProps)(DeliveryOrder);
