import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { selectors as orderSelectors } from '../features/order';
import { Translate } from 'react-redux-i18n';

const customModalStyles = {
    content: {
      zIndex: 6,
      height: '50%',

      width: '50%',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }

const SuccessModal: React.FC<any> = (props: any) => {
    const orderNumber = useSelector(orderSelectors.getOrderNumber);
    const {isOpen, closeModal} = props;
    return (
        <Modal isOpen={isOpen} style={customModalStyles}>
            <div style={{
            backgroundColor: 'rgba(255,255,255,.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
            }}>
                <div style={{
                backgroundColor: '#fff',
                padding: '4rem 2rem',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '50rem'
                }}>
                    <div className="popup-content">
                        <span className="icon-check"><img src={require("../assets/img/check.svg")} /></span>
                        <p className="popup-title">
                          <Translate value={`orderResult.children.thanks.value`} />
                        </p>
                        <p className="popup-subtitle"><Translate value={`orderResult.children.number.value`} /> - <span className="font-bold">№{orderNumber}</span></p>
                      <Translate value={`orderResult.children.notice.value`} />
                    </div>
                    <div className="popup-bottom">
                        <button className="btn btn-red" onClick={closeModal}>
                          <Translate value={`orderResult.children.continue.value`} />
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SuccessModal;
