import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { CounterReducer } from './features/counter';
import { SettingsReducer } from './features/settings';
import { ProductsReducer } from './features/products';
import { CategoriesReducer } from './features/categories';
import { OrderReducer } from './features/order';
import { DeliveryTypesReducer } from './features/deliveryTypes';

import LanguageReducer from './features/language/reducer';

import { syncTranslationWithStore, i18nReducer } from 'react-redux-i18n';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  count: CounterReducer,
  settings: SettingsReducer,
  products: ProductsReducer,
  categories: CategoriesReducer,
  order: OrderReducer,
  deliveryTypes: DeliveryTypesReducer,
  i18n: i18nReducer,
  language: LanguageReducer,
})

const store = createStore(
  rootReducer,
  applyMiddleware(thunk),
  // /* preloadedState, */ devToolsEnhancer({}),
);
syncTranslationWithStore(store);

export default store
