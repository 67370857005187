import { FETCH_SETTINGS_BEGIN, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILURE } from './actionTypes';
import HttpService from '../../services/httpService';
import config from '../../config';

const httpService = new HttpService();

export const fetchSettingsBegin = () => ({
  type: FETCH_SETTINGS_BEGIN,
});

export const fetchSettingsSuccess = (settings: any) => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: { settings },
});

export const fetchSettingsFailure = (error: any) => ({
  type: FETCH_SETTINGS_FAILURE,
  payload: { error },
});

export const fetchSettings = () => {
  return (dispatch: any) => {
    dispatch(fetchSettingsBegin());
    return httpService.get(`${config.baseURL}setups/settings`)
      .then((res: any) => {
        dispatch(fetchSettingsSuccess(res));
        return res;
      })
      .catch(e => dispatch(fetchSettingsFailure(e)));
  };
};
