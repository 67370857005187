export const languageType = {
  GET_APP_LANGUAGES_REQUEST: 'session/GET_APP_LANGUAGES_REQUEST',
  GET_APP_LANGUAGES_SUCCESS: 'session/GET_APP_LANGUAGES_SUCCESS',
  GET_APP_LANGUAGES_ERROR: 'session/GET_APP_LANGUAGES_ERROR',
  GET_APP_LANGUAGE_REQUEST: 'session/GET_APP_LANGUAGE_REQUEST',
  GET_APP_LANGUAGE_SUCCESS: 'session/GET_APP_LANGUAGE_SUCCESS',
  GET_APP_LANGUAGE_ERROR: 'session/GET_APP_LANGUAGE_ERROR',
  SET_APP_LANGUAGE_CODE: 'session/SET_APP_LANGUAGE_CODE',
}

/**
 * App languages
 */
export const languagesRequest = () => ({
  type: languageType.GET_APP_LANGUAGES_REQUEST,
});
export const languagesSuccess = (languages: any) => ({
  type: languageType.GET_APP_LANGUAGES_SUCCESS,
  payload: { languages },
});
export const languagesError = (error: any) => ({
  type: languageType.GET_APP_LANGUAGES_ERROR,
  payload: { error },
});

/**
 * App session language
 */
export const languageRequest = () => ({
  type: languageType.GET_APP_LANGUAGE_REQUEST,
});
export const languageSuccess = (language: any) => ({
  type: languageType.GET_APP_LANGUAGE_SUCCESS,
  payload: { language },
});
export const languageError = (error: any) => ({
  type: languageType.GET_APP_LANGUAGE_ERROR,
  payload: { error },
});

export const setAppLanguageCode = (langCode: any) => ({
  type: languageType.SET_APP_LANGUAGE_CODE,
  payload: { langCode },
});
