import React, {useState, useEffect} from 'react'
import { connect, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom'
import {getLanguage, getLanguages as getLanguagesRequest} from '../features/language/operations';
import {getLanguages} from '../features/language/selectors';

const Navbar = (props: any) => {
  const dispatch = useDispatch();
  const { languages } = props;
  const lang: any | string = process.env.REACT_APP_LANGUAGE_COOKIE;

  const [cookies, setCookie] = useCookies([lang]);
  const [langCode, setLangCode] = useState(cookies[lang] || process.env.REACT_APP_DEFAULT_LANGUAGE);

  useEffect(() => {
    dispatch(getLanguagesRequest());
  }, [])

  useEffect(() => {
    const storedLangCode = cookies[lang];
    if (langCode !== storedLangCode) {
      setCookie(lang, langCode, { path: '/' });
      setLangCode(langCode);
    }
    dispatch(getLanguage(langCode));
  }, [langCode]);

  const handleLangChange = (element: any) => {
    const { currentTarget: { value }} = element;
    setLangCode(value);
  };

  return (
    <nav>
      <div className="nav-wrapper cyan darken-1 px1">
        {/*<NavLink to="/" className="brand-logo">*/}
        {/*  Redux + TypeScript*/}
        {/*</NavLink>*/}
        {/*<ul className="right hide-on-med-and-down">*/}
        {/*  <li cy-data="home-nav-link">*/}
        {/*    <NavLink to="/">Home</NavLink>*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    <NavLink to="/about">About</NavLink>*/}
        {/*  </li>*/}
        {/*</ul>*/}
        <div className="container">
          <div style={{display: 'flex', flexFlow: 'row wrap', paddingRight: '1.5em'}}>
            <div style={{flex: '0 0 50%', width: '50%'}}>
              <select style={{height: '2.4rem', width: 'auto', margin: '10px'}}
                      value={langCode}
                      onChange={handleLangChange}>
                {
                  languages && languages
                    .map((language: any, index: number) => (<option value={language.code} key={index}>{language.name}</option>))
                }
              </select>
            </div>
          </div>
        </div>

      </div>
    </nav>
  )
}

export default connect((state: any) => {
  const languages = getLanguages(state);
  return {languages};
})(Navbar);
