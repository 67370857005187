import {
  FETCH_DELIVERY_TYPES_BEGIN,
  FETCH_DELIVERY_TYPES_SUCCESS,
  FETCH_DELIVERY_TYPES_FAILURE,
  // SET_CURRENT_DELIVERY_TYPE
} from './actionTypes';
import { setCurrentDeliveryType } from '../order/orderActions';
import HttpService from '../../services/httpService';
import config from '../../config';

const httpService = new HttpService();

export const fetchDeliveryTypesBegin = () => ({
  type: FETCH_DELIVERY_TYPES_BEGIN,
});

export const fetchDeliveryTypesSuccess = (deliveryTypes: any) => ({
  type: FETCH_DELIVERY_TYPES_SUCCESS,
  payload: { deliveryTypes },
});

export const fetchDeliveryTypesFailure = (error: any) => ({
  type: FETCH_DELIVERY_TYPES_FAILURE,
  payload: { error },
});

export const fetchDeliveryTypes = () => {
  return (dispatch: any) => {
    dispatch(fetchDeliveryTypesBegin());
    return httpService.get(`${config.baseURL}delivery_types`)
      .then((res: any) => {
        dispatch(fetchDeliveryTypesSuccess(res));
        const type = res.find((item: any) => item.code === 'courier');
        if (type) {
          dispatch(setCurrentDeliveryType(type.code));
        }
        return res;
      })
      .catch(e => dispatch(fetchDeliveryTypesFailure(e)));
  };
};

// export const setCurrentDeliveryType = (currentDeliveryType: string) => ({
//   type: SET_CURRENT_DELIVERY_TYPE,
//   payload: { currentDeliveryType },
// });
