import { 
  ORDER_ADD,
  ORDER_DELETE,
  ORDER_CHANGE_PRODUCT_COUNT,
  ORDER_INC_PRODUCT_COUNT,
  ORDER_DEC_PRODUCT_COUNT,
  SET_CURRENT_DELIVERY_TYPE,
  SET_DELIVERY_DATE,
  ORDER_REFRESH,
  ORDER_SHOW_SUCCESS_MODAL,
  ORDER_HIDE_SUCCESS_MODAL,
  ORDER_SET_NUMBER,
  ORDER_SHOW_DATA,
  ORDER_HIDE_DATA,
 } from './actionTypes';

const initialState: any = {
  data: [],
  currentDeliveryType: null,
  deliveryDate: new Date().toISOString().substr(0,10),
  isShowSuccess: false,
  orderNumber: null,
  isShowOrderData: false,
}

export default (state = initialState, {
  type,
  product,
  value,
  currentDeliveryType,
  deliveryDate,
  orderNumber
}: any) => {
  switch (type) {
    case ORDER_ADD: {
      const data = state.data;
      const idx = data.findIndex((item: any) => item.id === product.id);
      if (idx >= 0) {
        data[idx].count += product.count;
      } else {
        data.unshift(product);
      }
      return { ...state, data: [...data] };
    }
    case ORDER_DELETE: {
      const data = state.data;
      const idx = data.findIndex((item: any) => item.id === product.id);
      if (idx >= 0) {
        data.splice(idx, 1);
      }
      return { ...state, data: [...data] };
    }
    case ORDER_CHANGE_PRODUCT_COUNT: {
      const data = state.data;
      const idx = data.findIndex((item: any) => item.id === product.id);
      if (idx >= 0) {
        data[idx].count = value;
      }
      return { ...state, data: [...data] };
    }
    case ORDER_INC_PRODUCT_COUNT: {
      const data = state.data;
      const idx = data.findIndex((item: any) => item.id === product.id);
      if (idx >= 0) {
        data[idx].count += 1;
      }
      return { ...state, data: [...data] };
    }
    case ORDER_DEC_PRODUCT_COUNT: {
      const data = state.data;
      const idx = data.findIndex((item: any) => item.id === product.id);
      if (idx >= 0) {
        data[idx].count -= 1;
      }
      return { ...state, data: [...data] };
    }
    case SET_CURRENT_DELIVERY_TYPE: 
      return { ...state, currentDeliveryType };
    case SET_DELIVERY_DATE: 
      return { ...state, deliveryDate };
    case ORDER_REFRESH: 
      return { ...state, data: [], deliveryDate: new Date().toISOString().substr(0,10), orderNumber: null};
    case ORDER_SHOW_SUCCESS_MODAL: 
      return { ...state, isShowSuccess: true };
    case ORDER_HIDE_SUCCESS_MODAL: 
      return { ...state, isShowSuccess: false };
    case ORDER_SET_NUMBER: 
      return { ...state, orderNumber };
    case ORDER_SHOW_DATA:
      return { ...state, isShowOrderData: true };
    case ORDER_HIDE_DATA: 
      return { ...state, isShowOrderData: false };
    default:
      return state;
  }
}
