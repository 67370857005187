export const setOrderProductCount = (state: any) => {
    return {...state};
};

export const getTotalPrice = (state: any) => {
    const {order} = state;
    const {data = []} = order;
    if (data.length > 0) {
        return data.reduce((prev: any, cur: any) => {
            const productPrice = cur.count * cur.price;
            return (prev + Number(productPrice));
        }, 0);
    }
    return 0;
}

export const getDeliveryTypeByCode = (state: any) => {
    const {items} = state.deliveryTypes;
    const {currentDeliveryType} = state.order;
    return items.find((item: any) => item.code === currentDeliveryType);
}

export const getFormatDeliveryDate = (state: any) => {
    const {deliveryDate} = state.order;
    const date = new Date(deliveryDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
}

export const getOrderNumber = ({order}: any) => {
    const {orderNumber} = order;
    return orderNumber ? orderNumber.toString().padStart(9, '0') : '';
}

export const getOrdersProductsCount = ({order}: any) => {
    return order.data.length;
}
