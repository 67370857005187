import { 
  ORDER_ADD,
  ORDER_DELETE,
  ORDER_CHANGE_PRODUCT_COUNT,
  ORDER_INC_PRODUCT_COUNT,
  ORDER_DEC_PRODUCT_COUNT,
  SET_CURRENT_DELIVERY_TYPE,
  SET_DELIVERY_DATE,
  ORDER_REFRESH,
  ORDER_SHOW_SUCCESS_MODAL,
  ORDER_HIDE_SUCCESS_MODAL,
  ORDER_SET_NUMBER,
  ORDER_SHOW_DATA,
  ORDER_HIDE_DATA,
 } from './actionTypes';

export const orderAddProduct = (product: any) => ({
  type: ORDER_ADD,
  product, 
});

export const orderDeleteProduct = (product: any) => ({
  type: ORDER_DELETE,
  product, 
});

export const changeOrderProductCount = (product: any, value: number) => ({
  type: ORDER_CHANGE_PRODUCT_COUNT,
  product,
  value,
});

export const incrementOrderProductCount = (product: any) => ({
  type: ORDER_INC_PRODUCT_COUNT,
  product,
});

export const decrementOrderProductCount = (product: any) => ({
  type: ORDER_DEC_PRODUCT_COUNT,
  product,
});

export const setCurrentDeliveryType = (currentDeliveryType: string) => ({
  type: SET_CURRENT_DELIVERY_TYPE,
  currentDeliveryType,
});

export const setDeliveryDate = (deliveryDate: string) => ({
  type: SET_DELIVERY_DATE,
  deliveryDate,
});

export const resreshOrderData = () => ({
  type: ORDER_REFRESH,
});

export const handleOrderSuccesModal = (isShow: boolean) => ({
  type: isShow ? ORDER_SHOW_SUCCESS_MODAL : ORDER_HIDE_SUCCESS_MODAL,
});

export const setSuccessCreateOrder = (orderNumber: number) => ({
  type: ORDER_SET_NUMBER,
  orderNumber
});

export const handleOrderData = (isShow: boolean) => ({
  type: isShow ? ORDER_SHOW_DATA : ORDER_HIDE_DATA,
});


