import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleOrderData } from '../features/order/orderActions';
import { selectors } from '../features/order';
import { Translate } from 'react-redux-i18n';

export const DeliveryOrderTotal: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const count = useSelector(selectors.getOrdersProductsCount);
    const totalSum = useSelector(selectors.getTotalPrice);

    return (
        <div className="mobile-cart">
            <div className="delivery-order_total" onClick={() => dispatch(handleOrderData(true))}>
                <span><i className="fa fa-shopping-cart" aria-hidden="true"></i> <Translate value={`order.value`} /> ({count})</span>
                <span>{totalSum} <Translate value={`currency.children.uah.value`} /></span>
            </div>
        </div>
    );
}
