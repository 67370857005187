import HttpService from './httpService';
import config from '../config';
class ClientsService {
    private httpService: HttpService;
    
    constructor() {
      this.httpService = new HttpService();
    }
  
    public getClientByCellPhone = (cellPhone: string) => {
      return this.httpService.get(`${config.baseURL}clients/phone/${cellPhone}`);
    }

    public createClient = (body: any) => {
      return this.httpService.post(`${config.baseURL}clients`, JSON.stringify(body));
    }
  
  }
  
  export default ClientsService;