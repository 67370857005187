import {
  languagesRequest,
  languagesSuccess,
  languagesError,
  languageRequest,
  languageSuccess,
  languageError,
  setAppLanguageCode,
} from './actions';
import HttpService from '../../services/httpService';
import config from '../../config';
import { loadTranslations, setLocale } from 'react-redux-i18n';

const httpService = new HttpService();

export const getLanguages = () => (dispatch: any, getState: any) => {
  dispatch(languagesRequest());
  httpService.get(`${config.baseURL}i18n`)
    .then((response: any) => {
      dispatch(languagesSuccess(response));
    })
    .catch((error) => {
      dispatch(languagesError(error));
    });
};

export const getLanguage = (code: any | string = process.env.REACT_APP_DEFAULT_LANGUAGE) => (dispatch: any, getState: any) => {
  dispatch(languageRequest());
  httpService.get(`${config.baseURL}i18n/${code}`)
    .then((response: any) => {
      dispatch(languageSuccess(response));
      // update app language
      const translationsObject = {
        [code]: {
          ...response,
        }
      };
      try {
        dispatch(loadTranslations(translationsObject));
        dispatch(setLocale(code));
        dispatch(setAppLanguageCode(code));
      } catch (e) {
        console.log('getLanguage error: loadTranslations and setLocale are failed', e);
      }

    })
    .catch((error) => {
      dispatch(languageError(error));
    });
};
