export const ORDER_ADD  = 'ORDER_ADD';
export const ORDER_DELETE = 'ORDER_DELETE';
export const ORDER_CHANGE_PRODUCT_COUNT = 'ORDER_CHANGE_PRODUCT_COUNT';
export const ORDER_INC_PRODUCT_COUNT = 'ORDER_INC_PRODUCT_COUNT';
export const ORDER_DEC_PRODUCT_COUNT = 'ORDER_DEC_PRODUCT_COUNT';
export const SET_CURRENT_DELIVERY_TYPE = 'SET_CURRENT_DELIVERY_TYPE';
export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';
export const ORDER_REFRESH = 'ORDER_REFRESH';
export const ORDER_SHOW_SUCCESS_MODAL = 'ORDER_SHOW_SUCCESS_MODAL';
export const ORDER_HIDE_SUCCESS_MODAL = 'ORDER_HIDE_SUCCESS_MODAL';
export const ORDER_SET_NUMBER = 'ORDER_SET_NUMBER';
export const ORDER_SHOW_DATA = 'ORDER_SHOW_DATA';
export const ORDER_HIDE_DATA = 'ORDER_HIDE_DATA';

