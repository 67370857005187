import React, {useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { fetchSettings } from '../features/settings/settingsActions';
import { fetchProducts } from '../features/products/productsActions';
import { fetchCategories } from '../features/categories/categoriesActions';
import { fetchDeliveryTypes } from '../features/deliveryTypes/deliveryTypesActions';
import { handleOrderSuccesModal, resreshOrderData } from '../features/order/orderActions';
import MainContent from '../components/MainContent';
import DeliveryOrder from '../components/DeliveryOrder';
import { DeliveryOrderTotal } from '../components/DeliveryOrderTotal';
import SuccessModal from '../components/SuccessModal';

const customModalStyles = {
  content: { 
    zIndex: 6,
    height: '50%',
   
    width: '50%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}
const Home: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const {isShowSuccess} = props.order;

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchProducts());
    dispatch(fetchCategories());
    dispatch(fetchDeliveryTypes());
  }, []);

  const closeModal = () => {
    dispatch(handleOrderSuccesModal(false));
    dispatch(resreshOrderData());
  }

  return (
    <div>
      <main className="main-page">
        <MainContent />
        <DeliveryOrder />
      </main>
      <DeliveryOrderTotal />
      <SuccessModal isOpen={isShowSuccess} closeModal={closeModal} />
      {/* <Modal isOpen={isShowSuccess} style={customModalStyles}>
        <div style={{
          backgroundColor: 'rgba(255,255,255,.5)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}>
            <div style={{
              backgroundColor: '#fff',
              padding: '4rem 2rem',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              maxWidth: '50rem'
            }}>
                <div className="popup-content">
                    <span className="icon-check"><img src={require("../assets/img/check.svg")} /></span>
                    <p className="popup-title">Дякуємо за Ваше замовлення</p>
                    <p className="popup-subtitle">Номер Вашого замовлення - <span className="font-bold">№123456789</span></p>
                    Протягом 10 хвилин наш оператор зв'яжеться з Вами для підтвердження замовлення.
                </div>
                <div className="popup-bottom">
                    <button className="btn btn-red" onClick={closeModal}>Продовжити покупки</button>
                </div>
            </div>
        </div>
      </Modal> */}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  order: state.order,
});

export default connect(mapStateToProps)(Home);
