import { combineReducers } from 'redux';
import { languageType } from './actions';

const languages = (state = false, { type, payload }: any) => {
  switch (type) {
    case languageType.GET_APP_LANGUAGES_SUCCESS:
      return payload.languages;
    default:
      return state;
  }
};

const language = (state = false, { type, payload }: any) => {
  switch (type) {
    case languageType.GET_APP_LANGUAGE_SUCCESS:
      return payload.language;
    default:
      return state;
  }
};

const langCode = (state = false, { type, payload }: any) => {
  switch (type) {
    case languageType.SET_APP_LANGUAGE_CODE:
      return payload.langCode;
    default:
      return state;
  }
};

export default combineReducers({
  languages,
  language,
  langCode,
});
