class SettingsService {
    private settings: any;
  
    constructor(settings: any) {
      this.settings = settings;
    }
  
    public getSettingsValueByName = (name: string) => {
      if (this.settings && this.settings.length > 0) {
        const find = this.settings.find((item: any) => item.code === name);
        switch (find?.type) {
          case 'Integer': return Number(find.defaultValue);
          case 'String': return find.defaultValue.toString();
          case 'Time': return find.defaultValue.toString();
          default: return find?.defaultValue;
        }
      }
      return null;
    }
  
  }
  
  export default SettingsService;