export const checkDigit = (key: string, strict = false, allowedKeys?: string[]) => {
  const allowed: boolean = (key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Delete' || 
    key === 'Backspace' || key === 'Tab' || key === 'Tab' || key === 'Enter');
  const digit: boolean = (key >= '0' && key <= '9') || (!strict && (key === '.'));
  return digit || allowed || (allowedKeys && allowedKeys.includes(key));
};

export const handleDigitInputOnKeyDown = (e: React.KeyboardEvent) => {
  if (!e.metaKey && !e.ctrlKey && !checkDigit(e.key, true)) {
    e.preventDefault();
  }
};