import { FETCH_PRODUCTS_BEGIN, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAILURE } from './actionTypes';
import HttpService from '../../services/httpService';
import config from '../../config';

const httpService = new HttpService();

export const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN,
});

export const fetchProductsSuccess = (products: any) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products },
});

export const fetchProductsFailure = (error: any) => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: { error },
});

export const fetchProducts = (id: number | null = null) => {
  return (dispatch: any) => {
    let filter = '';
    if (id && id > 0) {
      filter = `?$filter=(category/id eq '${id}')`;
    }
    dispatch(fetchProductsBegin());
    return httpService.get(`${config.baseURL}products${filter}`)
      .then((res: any) => {
        dispatch(fetchProductsSuccess(res));
        return res;
      })
      .catch(e => dispatch(fetchProductsFailure(e)));
  };
};
