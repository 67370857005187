import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import { About } from './pages/About';
import Home from './pages/Home';

import { CookiesProvider, withCookies } from 'react-cookie';

const App: React.FC = () => {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Navbar />
        <Switch>
          <div className="container">
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
          </div>
        </Switch>
      </BrowserRouter>
    </CookiesProvider>
  )
}

export default withCookies(App)
