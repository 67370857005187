import React, {useEffect, useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import config from '../config';
import SettingsService from '../services/settingsService';
import { orderAddProduct, setCurrentDeliveryType, setDeliveryDate } from '../features/order/orderActions';
import { fetchProducts } from '../features/products/productsActions';

import { I18n, Translate } from 'react-redux-i18n';

const MainContent = (props: any) => {
    const dispatch = useDispatch();
    const {
        settings: {items: settingsItems = []},
        categories: {items: categoriesItems = []},
        products: {items: productsItems = []},
        deliveryTypes: {items: deliveryTypesItems = []},
        order: {currentDeliveryType, deliveryDate},
        language
    } = props;
    const [deliveryWorkTime, setDeliveryWorkTime] = useState('');
    const [deliveryWorkTimeW, setDeliveryWorkTimeW] = useState('');
    const [deliveryPhone, setDeliveryPhone] = useState('');

    const [category, setCategory] = useState(0);
    const [categories, setCategories] = useState(categoriesItems);
    const settingsService = new SettingsService(settingsItems);

    useEffect(() => {
    }, [language]);

    useEffect(() => {
        const start = settingsService.getSettingsValueByName('delivery_time_from');
        const end = settingsService.getSettingsValueByName('delivery_time_to');
        const startW = settingsService.getSettingsValueByName('delivery_time_from_weekend');
        const endW = settingsService.getSettingsValueByName('delivery_time_to_weekend');
        const phone = settingsService.getSettingsValueByName('delivery_phone');

        setDeliveryWorkTime(`${start || ''} - ${end || ''}`);
        setDeliveryWorkTimeW(`${startW || ''} - ${endW || ''}`);
        setDeliveryPhone(phone);
    }, [settingsItems]);

    useEffect(() => {
        if (categoriesItems.length > 0) {
            categoriesItems.unshift({id: 0, name: 'Всі'});
            setCategories([...categoriesItems]);
        }
    }, [categoriesItems]);

    const handleCategory = (id: number) => {
        setCategory(id);
        dispatch(fetchProducts(id));
    }

    const handleDeliveryType = (event: any) => {
        dispatch(setCurrentDeliveryType(event.target.value));
    }

    const handleDeliveryDate = (event: any) => {
        dispatch(setDeliveryDate(event.target.value));
    }

    const getProductPrice = (options: any) => {
        if (options && options.length > 0) {
            const item = options[0];
            const price = Number(item.price).toFixed(2);
            return price;
        }
        return Number(0).toFixed(2);
    }

    const getImageUrl = (images: any) => {
        if (images && images.length > 0) {
            return `${config.baseUrlImg}${images[0].uuid}`;
        }
        return require("../assets/img/thumbnail-default.png");
    }

    const addOrderProduct = (product: any) => {
        const price = getProductPrice(product.productOptions);
        dispatch(orderAddProduct({
            id: product.id,
            title: product.title,
            price,
            count: 1
        }));
    }

    const parseLanguageForCategory = (item: any) => {
        try {
            let translation =  item.name === 'Всі' ? I18n.t(`catalogs.children.all.value`) : I18n.t(`catalogs.children.${item.langKey}.value`);
            if (translation === 'value') {
                translation = item.name;
            }
            return translation;
        } catch (e) {
            console.log('i18n error: parseLanguage function failed.', e);
        }
    }

    return (
        <section className="main-content">
            <div className="container">
                <div className="place-block">
                    <div className="place-info"><span className="place-logo"><img src={require("../assets/img/logo.svg")} /></span>
                        <h1><Translate value={'place.value'} /></h1>
                        <div className="place-info_info">
                            <address className="place-info_address"><Translate value={'place.children.address.value'} /></address>
                            <address className="place-info_address" style={{marginTop: '5px', fontWeight: 300, color: '#03182d'}}>
                                {deliveryPhone}
                            </address>
                            <ul>
                                <li><Translate value={'schedule.children.weekdays.value'} /> {deliveryWorkTime}</li>
                                <li><Translate value={'schedule.children.weekend.value'} /> {deliveryWorkTimeW}</li>
                            </ul>
                        </div>
                    </div>
                    <figure className="place-pic" style={{backgroundImage: `url(${require("../assets/img/pic.jpg")})`}}></figure>
                </div>

                <div style={{display: 'flex', flexFlow: 'row wrap', paddingRight: '1.5em'}}>
                    <div style={{flex: '0 0 50%', width: '50%'}}>
                        <h3>
                            <Translate value={'form.children.deliveryDetails.value'} />
                        </h3>
                        <select id="delivery-select" onChange={handleDeliveryType}>
                            {
                                deliveryTypesItems && deliveryTypesItems.length > 0 &&
                                deliveryTypesItems.map((item: any) => (
                                    <option value={item.code} key={item.id}
                                            {...Object.assign({}, item.code === currentDeliveryType ? { selected: true } : {})}>
                                        {I18n.t(`form.children.deliveryType.children.${item.code}.value`)}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div style={{flex: '0 0 50%', width: '50%', paddingLeft: '1.5em'}}>
                        <h3><Translate value={`form.children.deliveryDate.children.date.value`} /></h3>
                        <input type="date" value={deliveryDate} onChange={handleDeliveryDate} />
                    </div>
                </div>
                <div style={{paddingBottom: '30px'}}></div>
                <h3><Translate value={`dishes.value`} /></h3>
                <div className="categories">
                    {
                        categories.length > 0 &&
                        categories.map((item: any) => (
                            <a key={item.id}
                               onClick={() => handleCategory(item.id)}
                               className={category === item.id ? 'active' : ''}
                               style={{cursor: 'pointer'}}>
                                {/*{item.name || ''}*/}
                                {/*{item.name === 'Всі' ? I18n.t(`catalogs.children.all.value`) : I18n.t(`catalogs.children.${item.langKey}.value`)}*/}
                                {parseLanguageForCategory(item)}
                            </a>
                        ))
                    }
                </div>
                <ul className="product-list">
                    {
                        productsItems.length > 0 &&
                        productsItems.map((item: any) => (
                            <li className="product-list_col" key={item.id}>
                                <a className="product-one" onClick={() => addOrderProduct(item)}>
                                    <div className="product-one_main">
                                        <div className="product-one_info">
                                            <p className="product-one_name">{item.title}</p>
                                            <div className="product-one_txt">{item.ingredients}</div>
                                        </div>
                                        <div className="product-one_pic" style={{backgroundImage: `url(${getImageUrl(item.productImages)})`}}></div>
                                    </div>
                                    <p className="product-one_price">{getProductPrice(item.productOptions)} <Translate value={`currency.children.uah.value`} /></p>
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    );
};

const mapStateToProps = (state: any) => ({
    settings: state.settings,
    categories: state.categories,
    products: state.products,
    deliveryTypes: state.deliveryTypes,
    order: state.order,
    language: state.language,
});

export default connect(mapStateToProps)(MainContent);
