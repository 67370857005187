import { 
  FETCH_DELIVERY_TYPES_BEGIN,
  FETCH_DELIVERY_TYPES_SUCCESS,
  FETCH_DELIVERY_TYPES_FAILURE,
  // SET_CURRENT_DELIVERY_TYPE
} from './actionTypes';

const initialState = {
  items: [],
  loading: false,
  error: null,
  currentDeliveryType: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_DELIVERY_TYPES_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_DELIVERY_TYPES_SUCCESS:
      return { ...state, loading: false, items: action.payload.deliveryTypes };
    case FETCH_DELIVERY_TYPES_FAILURE:
      return { ...state, loading: false, error: action.payload.error, items: [] };
    // case SET_CURRENT_DELIVERY_TYPE:
    //   return { ...state, currentDeliveryType: action.payload.currentDeliveryType };
    default:
      return state;
  }
}
